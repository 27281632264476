<template>
  <div class="page-company">
    <CompanyDetails />
  </div>
</template>

<script>
import CompanyDetails from '@/components/CompanyDetails.vue'

export default {
  name: 'Company',
  components: {
    CompanyDetails,
  },
}
</script>