<template>
  <div>
    <b-container fluid>

      <b-alert
        :show="dismissCountDown"
        dismissible
        variant="danger"
        @dismissed="dismissCountDown=0"
        @dismiss-count-down="countDownChanged"
      >
        <pre class="m-0">{{ error }}</pre>
      </b-alert>

      <b-card>

        <template slot="header">
          <b-card-title class="mt-2">
            <strong>
              Company Details 
              <span v-if="loaded"> - {{ company.name }}</span>
            </strong>
          </b-card-title>
        </template>
        
        <b-card-header>
          <b-row>
            <b-col>
              <b-form-group
                label="Job Title Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
                :disabled="contacts.loading"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filter-input"
                    v-model="contacts.jobTitle"
                    type="search"
                    placeholder=""
                    debounce="1000"
                    @update="value => refreshCompanyContacts()"
                    :disabled="contacts.loading"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button :disabled="contacts.loading || !contacts.jobTitle" @click="contacts.jobTitle = '', refreshCompanyContacts()">Clear</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="auto">
              <b-form inline class="float-right">
                <label class="mr-sm-2">Per page</label>
                <b-form-select size="sm" v-model="contacts.per_page" :options="pageOptions" @change="onChangedPerPage" :disabled="contacts.loading"/>
              </b-form>
            </b-col>
          </b-row>
        </b-card-header>
        <b-table
          :items="contacts.data"
          :fields="contactFields"
          show-empty
          hover
          small
          :busy="contacts.loading"
        >
          <template #cell(name)="row">
            {{ row.item.firstName }} {{ row.item.middleName }} {{ row.item.lastName }}
          </template>
          <template #cell(actions)="row">
            <b-button size="sm" @click="showContact(row)">Show Contact</b-button>
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle mx-2"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-model="contacts.page"
          :total-rows="contacts.total"
          :per-page="contacts.per_page"
          align="center"
          class="my-0"
          limit="10"
          @change="page => loadCompanyContacts()"
          :disabled="contacts.loading"
        />

        <b-overlay :show="loading" no-wrap />
      </b-card>
      
    </b-container>

    <b-modal
      id="contactModal"
      hide-footer
      no-close-on-backdrop
      title="Contact Details"
      size="xl"
    >
      <b-overlay :show="contactDetails.loading">
        <b-card v-if="contactDetails.loaded">
          <b-row class="mb-4">
            <b-col>
              <img v-if="contactDetails.picture" :src="contactDetails.picture" :alt="contactDetails.firstName + ' ' + contactDetails.middleName + ' ' + contactDetails.lastName" width="72" class="mr-2 rounded float-left" />
              <div>
                <h3>{{ contactDetails.firstName }} {{ contactDetails.middleName }} {{ contactDetails.lastName }}</h3>
                <small>{{ contactDetails.jobTitle }}</small>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h4>Contact details</h4>
              <p>{{ contactDetails.phone }}</p>
              <p>{{ contactDetails.email }}</p>
            </b-col>
            <b-col>
              <h4>Location</h4>
              <p>{{ contactDetails.street }}, {{ contactDetails.city }}, {{ contactDetails.state }} {{ contactDetails.zipCode }}, {{ contactDetails.country }}</p>
            </b-col>
          </b-row>
        </b-card>
      </b-overlay>
    </b-modal>

  </div>
</template>

<script>
export default {
  name: 'CompanyDetails',
  data() {
    return {
      loading: false,
      loaded: false,
      dismissSecs: 10,
      dismissCountDown: 0,
      error: null,
      company: null,
      contactFields: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'jobTitle',
          label: 'Job Title',
        },
        {
          key: 'actions',
          label: '',
        },
      ],
      pageOptions: [
        10,
        20,
        30
      ],
      contacts: {
        loading: false,
        loaded: false,
        total: 0,
        page: 1,
        per_page: 10,
        jobTitle: '',
        data: [],
      },
      contactDetails: {
        loading: false,
      },
    }
  },
  mounted: function() {
    this.company_id = this.$route.query.id
    this.loading = true
    if (this.company_id) {
      this.$store.dispatch('companies/loadCompanyData', {
        company_id: this.company_id
      })
      .then(data => {
        if (data && data.id == this.company_id) {
          this.company = data
          this.loaded = true
          return this.loadCompanyContacts()
        }
        throw `Company with id of ${this.company_id} does not exist`
      })
      .catch(e => {
        this.error = e
        this.showAlert()
      })
      .finally(() => {
        this.loading = false
      })

    }
  },
  methods: {
    async loadCompanyContacts() {
      await this.$nextTick()
      this.contacts.loading = true
      this.$store.dispatch('companies/loadCompanyContacts', {
        company_id: this.company_id,
        per_page: this.contacts.per_page,
        page: this.contacts.page,
        jobTitle: this.contacts.jobTitle,
      })
      .then(data => {
        this.contacts.data = data.data
        this.contacts.total = data.maxResults
        this.contacts.loading = false
        this.contacts.loaded = true
      })
    },
    refreshCompanyContacts() {
      this.contacts.page = 1
      this.loadCompanyContacts()
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    onChangedPerPage() {
      this.refreshCompanyContacts()
    },
    showContact(row) {
      this.contactDetails = Object.assign({}, {
        loading: true,
        loaded: false,
      })
      this.$bvModal.show('contactModal')
      this.$store.dispatch('companies/loadContactDetails', {
        person_id: row.item.id,
      })
      .then(data => {
        Object.assign(this.contactDetails, data, {
          loading: false,
          loaded: true,
        })
      })
    }
  }
}
</script>